import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>About</p>
                </div>
                <div>

                </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p>Hi. I am Aleksandar, nice to meet you. Please take a look around.</p>
                </div>
                <div>
                    <p>
                        Apart from my technical skills, I am a highly motivated individual with a strong work ethic. 
                        I approach tasks with a detail-oriented mindset and strive for excellence in everything I do. 
                        I am a quick learner and adapt easily to new technologies and methodologies. Moreover, 
                        I am an excellent team player and communicate effectively, allowing me to collaborate seamlessly 
                        with colleagues and contribute to a positive work environment.
                    </p>  
                </div>
            </div>
        </div>
    </div>
  )
}

export default About